//import React, {useState} from 'react';
import CreateProduct from '../../components/CreateProduct/CreateProduct';

//La page Admin pour créer un product card
const Admin =() => {
    return (
        <>
            <CreateProduct />
        </>
    )
}


export default Admin